import * as React from 'react';
import styles from './index.module.scss';
import Navbar from '../components/Navbar';
import Head from '../components/Head';
import Footer from '../components/Footer';

const IndexPage: React.FunctionComponent<{}> = () => {
  return (
    <div className={styles.IndexPage}>
      <Head />
      <Navbar />
      <div className={styles.Content}>
        <div className={styles.Landing}>
          <div className={styles.Hero}>
            <h1>Integrated Review Management</h1>
            <h2>
              Our Powerful and Easy to Use API allows you to build great customer experiences in
              minutes
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IndexPage;
