import * as React from 'react';
import styles from './Footer.module.scss';
import logoFull from '../../../assets/images/logo_full_white.png';
import { Link } from 'gatsby';

export interface FooterProps {}

const Footer: React.FunctionComponent<FooterProps> = () => {
  return (
    <div className={styles.Footer}>
      <div className={styles.Content}>
        <div className={styles.Logo}>
          <img src={logoFull} />
        </div>
        <nav className={styles.Links} role='navigation' aria-label='Footer navigation'>
          <ul>
            <li>Product</li>
            <li>
              <a href='https://opinous.com/features' target='_blank'>
                Features
              </a>
            </li>
            <li>
              <a href='https://opinous.com/pricing' target='_blank'>
                Plans & Pricing
              </a>
            </li>
          </ul>
          <ul>
            <li>Solutions</li>
            <li>
              <a href='https://opinous.com/solutions/estate-agents' target='_blank'>
                Opinous for Estate Agents
              </a>
            </li>
            <li>
              <a href='https://opinous.com/solutions/restaurants' target='_blank'>
                Opinous for Restaurants
              </a>
            </li>
          </ul>
          <ul>
            <li>Developers</li>
            <li>
              <Link to='/reference/tags/displaykit'>Drop-In Review Display Kit</Link>
            </li>
            <li>
              <Link to='/reference/tags/searchkit'>Drop-In Search Metadata Kit</Link>
            </li>
            <li>
              <Link to='/reference/rest-api'>REST API Reference</Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Footer;
